// clasess = ({type, size}) => {

//   switch(type)

// }

import { ThreeDots } from 'react-loader-spinner'

export const Button = function ({
  children,
  loading,
  disabled,
  type = 'button',
  size = 'md',
  className,
  onClick,
  text,
  variant = 'solid'
}) {
  const sizeClasess = {
    xs: 'items-center rounded border    px-2.5 py-1.5 text-xs font-medium  shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2',
    sm: 'items-center rounded-md border  px-3 py-2 text-sm font-medium leading-4  shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2',
    md: 'items-center rounded-md border  px-4 py-2 text-sm font-medium  shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2',
    lg: 'items-center rounded-md border  px-4 py-2 text-base font-medium  shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2',
    xl: 'items-center rounded-md border  px-6 py-3 text-base font-medium  shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2',
    fluid:
      'w-full items-center justify-center text-center rounded-md border disabled:bg-indigo-400 border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
  }

  const variantStyles = {
    solid: 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 disabled:bg-indigo-400 border-transparent text-white hover:bg-indigo-700 focus:ring-indigo-500',
    outline: 'border-indigo-600 text-indigo-600 hover:bg-indigo-50 focus:ring-indigo-500',
    ghost: 'text-indigo-600 hover:bg-indigo-50 focus:ring-indigo-500',
  }



  if (type == 'submit') {
    return (
      <button
        type={type}
        disabled={disabled}
        className={`flex ${sizeClasess[size]} ${className} ${variantStyles[variant]} justify-center`}
        onClick={onClick}
      >
        {loading ? (
          <span>
            <ThreeDots
              height="24"
              width="30"
              radius="10"
              color="#ffffff"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            ></ThreeDots>
          </span>
        ) : (
          <input type="submit" disabled={disabled} value={text} className="cursor-pointer" />
        )}
      </button>
    )
  }

  return (
    <button
      type={type}
      disabled={disabled}
      className={`flex  ${sizeClasess[size]} ${variantStyles[variant]} ${className}`}
      onClick={onClick}
    >
      {loading ? (
        <span className="mr-3">
          <ThreeDots
            height="10"
            width="30"
            radius="10"
            color="#ffffff"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          ></ThreeDots>
        </span>
      ) : (
        ''
      )}
      {children}
    </button>
  )
}

export const ButtonSecondary = function ({
  children,
  loading,
  disabled,
  type,
  size = 'md',
  className,
  onClick,
}) {
  const sizeClasess = {
    xs: 'inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
    sm: 'inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
    md: 'inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
    lg: 'inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
    xl: 'inline-flex items-center rounded-md border border-gray-300 bg-white px-6 py-3 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
    fluid: 'w-full inline-flex items-center justify-center text-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
  }

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={`${sizeClasess[size]} ${className}`}
    >
      {children}
    </button>
  )
}

export const IconButton = function ({
  children,
  loading,
  disabled,
  type,
  size = 'xs',
  className,
  onClick,
}) {
  const sizeClasess = {
    xs: 'inline-flex items-center rounded-md shadow-sm  border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 hover:border-gray-300 hover:shadow hover:bg-gray-50 focus:outline-none ',
    sm: 'inline-flex items-center rounded-md shadow-sm border border-gray-300 hover:border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 hover:shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
    md: 'inline-flex items-center rounded-md shadow-sm border border-gray-300  hover:border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700  hover:shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
    lg: 'inline-flex items-center rounded-md shadow-sm border border-gray-300  hover:border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700  hover:shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
    xl: 'inline-flex items-center rounded-md shadow-sm border border-gray-300 hover:border-gray-300 bg-white px-6 py-3 text-base font-medium text-gray-700  hover:shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
  }

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={`${sizeClasess[size]} ${className}`}
    >
      {children}
    </button>
  )
}

export const ButtonRed = function ({
  children,
  loading,
  disabled,
  type,
  size = 'md',
  className,
  onClick,
}) {
  const sizeClasess = {
    xs: 'inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-gray-100',
    sm: 'inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-gray-100',
    md: 'inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-gray-100',
    lg: 'inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-gray-100',
    xl: 'inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-gray-100',
  }

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={`${sizeClasess[size]} ${className}`}
    >
      {loading ? (
        <span className="mr-3">
          <ThreeDots
            height="10"
            width="30"
            radius="10"
            color="#ffffff"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          ></ThreeDots>
        </span>
      ) : (
        ''
      )}
      {children}
    </button>
  )
}

export const ButtonYellow = function ({
  children,
  loading,
  disabled,
  type,
  size = 'md',
  className,
  onClick,
}) {
  const sizeClasess = {
    xs: 'inline-flex items-center justify-center rounded-md border border-yellow-200 bg-yellow-100 px-4 py-2 text-sm font-medium text-yellow-600 shadow-sm hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 focus:ring-offset-yellow-100',
    sm: 'inline-flex items-center justify-center rounded-md border border-yellow-200 bg-yellow-100 px-4 py-2 text-sm font-medium text-yellow-600 shadow-sm hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 focus:ring-offset-yellow-100',
    md: 'inline-flex items-center justify-center rounded-md border border-yellow-200 bg-yellow-100 px-4 py-2 text-sm font-medium text-yellow-600 shadow-sm hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 focus:ring-offset-yellow-100',
    lg: 'inline-flex items-center justify-center rounded-md border border-yellow-200 bg-yellow-100 px-4 py-2 text-sm font-medium text-yellow-600 shadow-sm hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 focus:ring-offset-yellow-100',
    xl: 'inline-flex items-center justify-center rounded-md border border-yellow-200 bg-yellow-100 px-4 py-2 text-sm font-medium text-yellow-600 shadow-sm hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 focus:ring-offset-yellow-100',
  }

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={`${sizeClasess[size]} ${className}`}
    >
      {loading ? (
        <span className="mr-3">
          <ThreeDots
            height="10"
            width="30"
            radius="10"
            color="#ffffff"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          ></ThreeDots>
        </span>
      ) : (
        ''
      )}
      {children}
    </button>
  )
}
