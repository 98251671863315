import React, { useRef } from 'react'

export default function TextField({
  name,
  type = 'text',
  error,
  register,
  validations,
  value,
  onChange,
  onBlur,
  className,
  label,
  placeholder,
  disabled,
  helpComponent,
}) {
  const errorClass =
    'text-red-900 border-red-300 focus:border-red-500 focus:ring-red-500 focus:outline-none placeholder-red-300'
  const defaultClass =
    'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
  const disabledClass =
    'disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm'

  const stateClasses = (error, disabled) => {
    if (error?.message) return errorClass
    if (disabled) return disabledClass
    return defaultClass
  }

  function registerData() {
    if (!register) return {}
    return {
      ...register(name, {
        ...validations,
        onChange: (e) => (onChange ? onChange(e.target.value) : ''),
        onBlur: (e) => (onBlur ? onBlur(e.target.value) : ''),
      }),
    }
  }

  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
        {helpComponent ? helpComponent() : ''}
      </div>
      <div className="mt-1">
        <input
          onChange={(e) => (onChange ? onChange(e.target.value) : '')}
          onBlur={(e) => (onBlur ? onBlur(e.target.value) : '')}
          type={type}
          name={name}
          value={value}
          id={name}
          disabled={disabled}
          {...registerData()}
          className={`block w-full rounded-md sm:text-sm ${stateClasses(
            error,
            disabled
          )}`}
          placeholder={placeholder}
        />
        {error?.message ? (
          <p className="mt-2 text-sm text-red-600">{error?.message}</p>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export const TextFieldWithAddon = function ({
  name,
  error,
  addonText,
  register,
  validations,
  value,
  onChange,
  onBlur,
  className,
  label,
  placeholder,
  disabled,
  type = 'text',
}) {
  const errorClass =
    'text-red-900 border-red-300 focus:border-red-500 focus:ring-red-500 focus:outline-none placeholder-red-300'
  const defaultClass =
    'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500'
  const disabledClass =
    'disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm'

  const stateClasses = (error, disabled) => {
    if (error?.message) return errorClass
    if (disabled) return disabledClass
    return defaultClass
  }

  function registerData() {
    if (!register) return {}
    return { ...register(name, { ...validations }) }
  }
  return (
    <div className={className}>
      <label
        htmlFor="email"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="mt-1 flex rounded-md">
        <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
          {addonText}
        </span>
        <input
          // ref={ref}
          value={value}
          onChange={(e) => (onChange ? onChange(e.target.value) : '')}
          onBlur={(e) => (onBlur ? onBlur(e.target.value) : 's')}
          type={type}
          name={name}
          id={name}
          disabled={disabled}
          {...registerData()}
          className={`block w-full rounded-none rounded-r-md sm:text-sm ${stateClasses(
            error,
            disabled
          )}`}
          placeholder={placeholder}
        />
      </div>
      {error?.message ? (
        <p className="mt-2 text-sm text-red-600">{error?.message}</p>
      ) : (
        ''
      )}
    </div>
  )
}
