const { useState, useEffect } = require('react')
import { setCookie, deleteCookie } from 'cookies-next'
import api from '@/api'
import { useRouter } from 'next/router'
import { setCurrentUser } from '@/store/sessionSlice'

const OnboardingRouteMap = {
  signed_up: '/company-details',
  plan_selected: '/company-address',
  address_added: '/company-address',
  onboarded: '/get-started',
  under_review: '/get-started',
  rejected: '/get-started',
  blocked: '/get-started'
}

export const useSession = function () {
  const [status, setStatus] = useState('authenticating')
  const [session, setSession] = useState(null)
  const router = useRouter()

  function setSessionCookie(headers) {
    setCookie('access-token', headers['access-token'])
    setCookie('token-type', headers['token-type'])
    setCookie('uid', headers['uid'])
    setCookie('expiry', headers['expiry'])
    setCookie('client', headers['client'])
  }

  function removeSession() {
    // deleteCookie('access-token')
    // deleteCookie('token-type')
    // deleteCookie('uid')
    // deleteCookie('expiry')
    // deleteCookie('client')
  }

  const signIn = async function (params, redirectUrl) {
    setStatus('authenticating')
    const response = await api.auth.signIn(params)
    if (response.data) {
      setSessionCookie(response.headers)
      setStatus('authenticated')
      setSession(response.data.data)

      const onboardingStatus = response.data.data.company?.onboardingStatus
      if (onboardingStatus != 'onboarded' && onboardingStatus != 'under_review') {
        router.push(OnboardingRouteMap[onboardingStatus])
      } else {
        if (redirectUrl) {
          router.push(redirectUrl)
        }
      }
    } else {
      removeSession()
      setStatus('unauthenticated')
    }
    return response.data
  }

  const signOut = async function (redirectUrl) {
    await api.auth.signOut()
    removeSession()
    if (redirectUrl) {
      router.push(redirectUrl)
    }
  }

  const signUp = async function (params) {
    return api.auth.signUp(params)
  }

  const validate = async function (callback) {
    setStatus('authenticating')
    try {
      const response = await api.auth.validate()
      if (response) {
        setStatus('authenticated')
        setSession(response.data.data)
        callback(response.data.data)
      } else {
        setStatus('unauthenticated')
        removeSession()
        setSession(null)
        callback(null)
      }
    } catch (e) {
      setStatus('unauthenticated')
      removeSession()
      setSession(null)
      callback(null)
    }
  }

  return { status, session, signIn, signOut, signUp, validate, setSessionCookie }
}
